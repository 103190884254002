import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Rating from '@mui/material/Rating'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import CommentsService from '../../servicio/CommentsService';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function InsertComments(props) {

  const [codigo_usuario, setCodigo_Usuario] = React.useState('')
  const [puntuacion, setPuntuacion] = React.useState(0)
  const [comentario, setComentario] = React.useState('')
  const [telefono, setTelefono] = React.useState('')
  const [email, setEmail] = React.useState('')

  const [open, setOpen] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [vertical, setVertical] = React.useState('bottom');
  const [horizontal, setHorizontal] = React.useState('center');
  const [clickguardar, setClickGuardar] = React.useState(false);

  const [colorMessage, setColorMessage] = React.useState('success')

  const changeHandler = (event) => {
    switch (event.target.name) {
      case 'codigo_usuario':
        setCodigo_Usuario(event.target.value);
        break;
      case 'comentario':
        setComentario(event.target.value);
        break;
      case 'telefono':
        setTelefono(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        break;
      case 'rating-valoracion':
        setPuntuacion(event.target.value);
        break;
    }

  }

  const close = () => {
    
    setCodigo_Usuario('');
    setComentario('');
    setTelefono('');
    setEmail('');
    setPuntuacion(0);
    setClickGuardar(false);

    props.clickCloseInsertComment()
  }
  

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const validarCampos = async (event) => {
    event.preventDefault();

    setClickGuardar(true);
    setClickGuardar((state) => {

      if (codigo_usuario.length === 0 || codigo_usuario === ' ') {
        setMessage('El campo Nombre no puede estar vacío');
        setMessage((state) => {
          setOpen(true);
          setClickGuardar(false);
          return state;
        });
        return
      } else if (telefono.length === 0 || telefono === ' ') {
        setMessage('El campo Teléfono no puede estar vacío');
        setMessage((state) => {
          setOpen(true);
          setClickGuardar(false);
          return state;
        });
        return
      } else if (comentario.length === 0 || comentario === ' ') {
        setMessage('El campo Comentario no puede estar vacío');
        setMessage((state) => {
          setOpen(true);
          setClickGuardar(false);
          return state;
        });
        return
      } else if (puntuacion === 0) {
        setMessage('Debe indicar la puntuación al prestador de servicios');
        setMessage((state) => {
          setOpen(true);
          setClickGuardar(false);
          return state;
        });
        return
      }

      CommentsService
      .crear(props.codigo_prestador, codigo_usuario, puntuacion, comentario, telefono, email)
      .then((response) => {
        close();
        setColorMessage('success');
        setMessage('Comentario registrado con exito');
        setMessage((state) => {
          setOpen(true);
          props.clickInsertComment(codigo_usuario, puntuacion, comentario)
          return state;
        });
      },
        error => {
          setColorMessage('error');
          setMessage('Error guardando comentario: ' + error.toString());
          setMessage((state) => {
            setOpen(true);
            return state;
          });
        })

      return state;
    });
  }

  return (
    <Box>
      <Dialog open={props.openInsertComment} onClose={close} hideBackdrop={false}>
        <Box component="form" onSubmit={validarCampos}>
          <DialogTitle>Tu opinión es importante</DialogTitle>
          <DialogContent sx={{
            '& .MuiTextField-root': { m: 0.5 },
          }}>
            <DialogContentText>
              Déjanos tu comentario sobre el servicio recibido por el prestador seleccionado.
            </DialogContentText>
            <TextField
              required
              autoFocus
              id="codigo_usuario"
              name="codigo_usuario"
              label="Nombre"
              type="text"
              fullWidth
              inputProps={{ maxLength: 50 }}
              onChange={changeHandler}
              helperText="Este campo es requerido"
            />
            <TextField
              required
              id="telefono"
              name="telefono"
              label="Teléfono"
              type="tel"
              fullWidth
              inputProps={{ maxLength: 20 }}
              onChange={changeHandler}
            />
            <TextField
              id="email"
              name="email"
              label="Email"
              type="email"
              fullWidth
              inputProps={{ maxLength: 20 }}
              onChange={changeHandler}
            />
            <TextField
              id="comentario"
              name="comentario"
              multiline
              rows={4}
              label="Comentario"
              type="email"
              fullWidth
              inputProps={{ maxLength: 200 }}
              onChange={changeHandler}
            />
            <Stack sx={{ m: 0.5 }}>
              <Typography component="legend">Valoración</Typography>
              <Rating onChange={changeHandler} name="rating-valoracion" precision={0.5} defaultValue={0} size="large" />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ marginRight: 2 }}>
            <Button onClick={close} variant="outlined">Cancelar</Button>
            <LoadingButton
              type="submit"
              loading={clickguardar}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
              color="success"
            >
              Enviar
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} variant="filled" anchorOrigin={{ vertical, horizontal }}>
        <Alert onClose={handleClose} severity={colorMessage} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
