import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChatBot from 'react-simple-chatbot';
import MyLocationIcon from '@mui/icons-material/MyLocation';

import { ThemeProvider } from 'styled-components';

// all available props
const theme = {
  background: '#f5f8fb',
  fontFamily: 'Helvetica Neue',
  headerBgColor: 'linear-gradient(to left bottom, rgb(0, 77, 205) 10%, rgba(0, 77, 205, 0.584))',
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: '#EF6C00',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};

class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      gender: '',
      age: '',
    };
  }

  componentWillMount() {
    const { steps } = this.props;
    const { name, gender, age } = steps;

    this.setState({ name, gender, age });
  }

  render() {
    const { name, gender, age } = this.state;
    return (
      <div style={{ width: '100%' }}>
        <h3>Summary</h3>
        <table>
          <tbody>
            <tr>
              <td>Name</td>
              <td>{name.value}</td>
            </tr>
            <tr>
              <td>Gender</td>
              <td>{gender.value}</td>
            </tr>
            <tr>
              <td>Age</td>
              <td>{age.value}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

Review.propTypes = {
  steps: PropTypes.object,
};

Review.defaultProps = {
  steps: undefined,
};

class ChatBotReact extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <ChatBot
          floating={true}          
          headerTitle="Asistente virtual"
          placeholder="Escriba su mensaje"
          style={{
            zIndex: 1100,
            height: '90%'
          }}
          contentStyle={{
            height: '85%'
          }}
          floatingStyle={{
            bottom: '25px',
            right: '90px',
            backgroundColor: '#00d4ff',
            width: 50,
            height: 50,
            boxShadow: `0 3px 6px 0 `,
            '&.kOHYiY:hover': {
              boxShadow: `0 6px 9px 0 `,
            }
          }}
          steps={[
            {
              id: '1',
              message: 'Buenas tardes, puedes indicarme tu nombre?',
              trigger: 'name',
            },
            {
              id: 'name',
              user: true,
              trigger: '3',
            },
            {
              id: '3',
              message: 'Hola {previousValue}! cual es tu destino en esta temporada?',
              trigger: 'destiny',
            },
            {
              id: 'destiny',
              options: [
                { value: 'playa', label: 'Playa', trigger: 'pl' },
                { value: 'montaña', label: 'Montaña', trigger: 'mn' },
                { value: 'llano', label: 'Llano', trigger: 'll' },
                { value: 'ciudad', label: 'Ciudad', trigger: 'ci' },
              ],
            },
            {
              id: 'pl',
              message: 'En cual servicio estas interesado?',
              trigger: 'services_pl',
            },
            {
              id: 'services_pl',
              options: [
                { value: 'posada', label: 'Posadas', trigger: 'pos' },
                { value: 'restaurant', label: 'Restaurant', trigger: 'res' },
                { value: 'lanchas', label: 'Lanchas', trigger: 'lan' },
                { value: 'toldos', label: 'Toldos', trigger: 'tol' },
                { value: 'paseos', label: 'Paseos', trigger: 'pas' },
                { value: 'todos', label: 'Todos', trigger: 'tod' },
              ],
            },
            {
              id: 'mn',
              message: 'En cual servicio estas interesado?',
              trigger: 'services_mn',
            },
            {
              id: 'services_mn',
              options: [
                { value: 'posada', label: 'Posadas', trigger: 'pos' },
                { value: 'restaurant', label: 'Restaurant', trigger: 'res' },
                { value: 'paseos', label: 'Paseos', trigger: 'pas' },
                { value: 'todos', label: 'Todos', trigger: 'tod' },
              ],
            },
            {
              id: 'll',
              message: 'En cual servicio estas interesado?',
              trigger: 'services_ll',
            },
            {
              id: 'services_ll',
              options: [
                { value: 'posada', label: 'Posadas', trigger: 'pos' },
                { value: 'restaurant', label: 'Restaurant', trigger: 'res' },
                { value: 'paseos', label: 'Paseos', trigger: 'pas' },
                { value: 'todos', label: 'Todos', trigger: 'tod' },
              ],
            },
            {
              id: 'ci',
              message: 'En cual servicio estas interesado?',
              trigger: 'services_ci',
            },
            {
              id: 'services_ci',
              options: [
                { value: 'restaurant', label: 'Restaurant', trigger: 'res' },
                { value: 'paseos', label: 'Paseos', trigger: 'pas' },
                { value: 'todos', label: 'Todos', trigger: 'tod' },
              ],
            },
            {
              id: 'pos',
              message: 'Para prestarte un mejor servicio serás redireccionado a la busqueda de posadas?',
              trigger: 'age',
            },
            {
              id: 'pos',
              message: 'Para prestarte un mejor servicio serás redireccionado a la busqueda de posadas',
              trigger: 'age',
            },
            {
              id: 'res',
              message: 'Para prestarte un mejor servicio serás redireccionado a la busqueda de restaurantes',
              trigger: 'age',
            },
            {
              id: 'lan',
              message: 'Para prestarte un mejor servicio serás redireccionado a la busqueda de lanchas para traslados',
              trigger: 'age',
            },
            {
              id: 'tol',
              message: 'Para prestarte un mejor servicio serás redireccionado a la busqueda de toldos',
              trigger: 'age',
            },
            {
              id: 'pas',
              message: 'Para prestarte un mejor servicio serás redireccionado a la busqueda de paseos',
              trigger: 'age',
            },
            {
              id: 'tod',
              message: 'Para prestarte un mejor servicio te mostraremos todos los servicios registrados en nuestra plataforma',
              trigger: 'age',
            },
            {
              id: '5',
              message: 'How old are you?',
              trigger: 'age',
            },
            {
              id: 'age',
              user: true,
              trigger: '7',
              validator: (value) => {
                if (isNaN(value)) {
                  return 'value must be a number';
                } else if (value < 0) {
                  return 'value must be positive';
                } else if (value > 120) {
                  return `${value}? Come on!`;
                }

                return true;
              },
            },
            {
              id: '7',
              message: 'Great! Check out your summary',
              trigger: 'review',
            },
            {
              id: 'review',
              component: <Review />,
              asMessage: true,
              trigger: 'update',
            },
            {
              id: 'update',
              message: 'Would you like to update some field?',
              trigger: 'update-question',
            },
            {
              id: 'update-question',
              options: [
                { value: 'yes', label: 'Yes', trigger: 'update-yes' },
                { value: 'no', label: 'No', trigger: 'end-message' },
              ],
            },
            {
              id: 'update-yes',
              message: 'What field would you like to update?',
              trigger: 'update-fields',
            },
            {
              id: 'update-fields',
              options: [
                { value: 'name', label: 'Name', trigger: 'update-name' },
                { value: 'gender', label: 'Gender', trigger: 'update-gender' },
                { value: 'age', label: 'Age', trigger: 'update-age' },
              ],
            },
            {
              id: 'update-name',
              update: 'name',
              trigger: '7',
            },
            {
              id: 'update-gender',
              update: 'gender',
              trigger: '7',
            },
            {
              id: 'update-age',
              update: 'age',
              trigger: '7',
            },
            {
              id: 'end-message',
              message: 'Thanks! Your data was submitted successfully!',
              end: true,
            },
          ]}
        />
      </ThemeProvider>
    );
  }
}

export default ChatBotReact;