import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import playa from '../../assets/images/playa.png';
import montana from '../../assets/images/montana.png';
import llano from '../../assets/images/llanos.png';
import ciudad from '../../assets/images/ciudad.png';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Search from './Search';
import FunctionsCommon from '../functions/FunctionsCommon';

import Zoom from '@mui/material/Slide';

const images = [
  {
    id: 'playa',
    url: `${playa}`,
    title: 'Playa',
    width: '21%',
  },
  {
    id: 'montana',
    url: `${montana}`,
    title: 'Montaña',
    width: '21%',
  },
  {
    id: 'llano',
    url: `${llano}`,
    title: 'Llano',
    width: '21%',
  },
  {
    id: 'ciudad',
    url: `${ciudad}`,
    title: 'Ciudad',
    width: '21%',
  },
];
const playas = [
  {
    id: 'posadas',
    title: 'posadas',
  },
  {
    id: 'restaurant',
    title: 'Restaurant',
  },
  {
    id: 'lanchas',
    title: 'Lanchas',
  },
  {
    id: 'toldos',
    title: 'Toldos',
  },
  {
    id: 'paseos',
    title: 'Paseos',
  },
];
const montanas = [
  {
    id: 'posadas',
    title: 'Posadas',
  },
  {
    id: 'restaurant',
    title: 'Restaurant',
  },
  {
    id: 'paseos',
    title: 'Paseos',
  },
];
const llanos = [
  {
    id: 'posadas',
    title: 'Posadas',
  },
  {
    id: 'paseos',
    title: 'Paseos',
  },
];
const ciudades = [
  {
    id: 'posadas',
    title: 'Posadas',
  },
];
const ImageButton = styled(ToggleButton)(({ theme }) => ({
  position: 'relative',
  height: 100,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
  },
  '&.MuiToggleButton-root.Mui-selected': {
    backgroundColor: 'black',
    boxShadow: `0 1px 3px 0`,
    transform: 'scale(1.001)',
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
  borderRadius: 26
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
  borderRadius: 26
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}));



export default function ContainerEco(props) {

  const [opcioneco, setOpcionEco] = React.useState(props.opciones !== undefined ? props.opciones[0].title : []);

  const [estatus, setEstatus] = useState(0)
  const [idActual, setidActual] = useState()
  const [idAnterior, setidAnterior] = useState()
  const [opciones, setOpciones] = useState()
  const [activo, setActivo] = useState(false)
  const OnClickToggleEco = (idActual, idAnterior, estatus) => {

    
    setActivo(!activo);
    switch (idActual) {
      case "playa":
        setOpciones(playas)
        break;
      case "montana":
        setOpciones(montanas)
        break;
      case "llano":
        setOpciones(llanos)
        break;
      case "ciudad":
        setOpciones(ciudades)
        break;
      default:
        setOpciones(ciudades)
        break;
    }

    
    if (estatus === 1) {
      if (idActual === idAnterior) {
        setidActual(idActual)
        setidAnterior(idAnterior)
        return setEstatus(0)
      } else {
        setidActual(idActual)
        setidAnterior(idAnterior)
        return setEstatus(1)
      }
    } else {
      setidActual(idActual)
      setidAnterior(idActual)
      return setEstatus(1)
    }

  }
  const [alignment, setAlignment] = React.useState(images[0].title);

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleChangeECO = (event, opcion) => {
    setOpcionEco(opcion);
    
  };

  return (
    <Search
        localidades={props.localidades}        
        clickBuscar={props.clickBuscar}
        buscarPrestador={props.buscarPrestador}
        ecosistema={estatus === 1 ? idActual : 0}
        opcioneco={estatus === 1 ? opcioneco : 0}
        loading={props.loading}
      />
    
  );
}


