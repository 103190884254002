import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import {
    Box,
    IconButton,
    Container,
    Stack,
    Rating,
    Typography,
    Alert,
    AlertTitle,
} from '@mui/material';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { makeStyles } from '@mui/styles';

import CommentTwoToneIcon from '@mui/icons-material/CommentTwoTone';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@mui/material/styles';

import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0.1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export default function Search(props) {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.comentarios.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const useStyles = makeStyles(() => ({
        alertInfo: {
            height: 80,
            marginBottom: 4,
            backgroundColor: '#EBEFFF',
            '&:hover': {
                transform: 'scale(1.0001)',
                boxShadow: `0 1px 3px 0`,
                backgroundColor: '#D1E9F6',
                '& .css-132jxnu-MuiPaper-root': {
                    backgroundColor: '#D1E9F6'
                }
            },
        },
        paperStyle: {
            '&.MuiPaper-root': {
                boxShadow: 'none',
                backgroundColor: '#EBEFFF',
            },
        },
        stackDetails: {
            marginLeft: '-3px',
            '&.css-29nqov-MuiStack-root>:not(style)+:not(style)': {

            }
        },
    }));

    const classes = useStyles();

    const matches = useMediaQuery('(max-width:600px)');

    return (
        <Container sx={{ mt: 1, mb: 1, height: '100%', overflowY: 'scroll' }}>
            <TableContainer component={Paper} sx={{ background: 'transparent', boxShadow: 'none', overflow: 'unset' }}>
                <Table aria-label="simple table" size="small" loading>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? props.comentarios.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : props.comentarios
                        ).map((comentario, index) => (
                            <TableRow
                                key={comentario.codigo_usuario + index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{ paddingBottom: 0, paddingTop: 0, border: 0 }}>
                                    <Alert className={classes.alertInfo}
                                        iconMapping={{
                                            info: <CommentTwoToneIcon fontSize="inherit" />,
                                        }}
                                        variant="outlined" severity="info" sx={{ width: '100%', padding: { sm: '0px 6px 0px 16px' }, backgroundColor: '#EBEFFF', }} >
                                        <AlertTitle sx={{ fontSize: { sm: 14, } }}>{comentario.codigo_usuario}</AlertTitle>
                                        <Typography sx={{ fontSize: { sm: 14, }, fontStyle: 'italic' }} variant="body1" color="text.secondary">
                                            {comentario.comentario}
                                        </Typography>

                                        <Stack
                                            direction={{ xs: 'column', sm: 'row' }}
                                            spacing={{ xs: 0, sm: 0, md: 7 }}
                                            className={classes.stackDetails}
                                        >
                                            <BootstrapTooltip title={comentario.puntuacion} placement="right-end">
                                                <Item className={classes.paperStyle}>
                                                    <Rating sx={{
                                                        verticalAlign: 'bottom',
                                                    }}
                                                        name="size-large"
                                                        value={comentario.puntuacion}
                                                        precision={0.1}
                                                        size="small"
                                                        readOnly />
                                                </Item>
                                            </BootstrapTooltip>
                                        </Stack>
                                    </Alert>
                                </TableCell>
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                sx={{ justifyContent: 'center' }}
            >
                <TablePagination
                    rowsPerPageOptions={matches ? [] : [1, 2, 3, 5, 10, 25, { label: 'Todos', value: -1 }]}
                    labelRowsPerPage={"Resultados por página"}
                    count={props.comentarios.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        MenuProps: { classes: { paper: classes.selectDropdown } }
                    }}
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} de ${count !== -1 ? count : `Más de ${to}`}`}
                    classes={{ menuItem: classes.menuItem }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </Stack>

        </Container >
    )
}

