import {Component, useState} from 'react'
import Button_Outline from './Button_OutLine'
import {Container} from '@mui/material';
import {ContainerUI} from './BaseComponents';
import ButtonGroup from '@mui/material/ButtonGroup';
const playa = [
  {
    id: 'posadas',
    title: 'posadas',
  },
  {
    id: 'restaurant',
    title: 'Restaurant',
  },
  {
    id: 'lanchas',
    title: 'Lanchas',
  },
  {
    id: 'toldos',
    title: 'Toldos',
  },
  {
    id: 'paseos',
    title: 'Paseos',
  },
];
const montana = [
  {
    id: 'posadas',
    title: 'Posadas',
  },
  {
    id: 'restaurant',
    title: 'Restaurant',
  },
  {
    id: 'paseos',
    title: 'Paseos',
  },
];
const llano = [
  {
    id: 'posadas',
    title: 'Posadas',
  },
  {
    id: 'paseos',
    title: 'Paseos',
  },
];
const ciudad = [
  {
    id: 'posadas',
    title: 'Posadas',
  },
];
class ContainerEcoOptions extends Component{ 
  constructor(props){
    super(props)
  }
  componentDidMount(){

  }
  componentDidUpdate(prevProps, prevState){

  }
 render(){
  const { opciones } = this.props
  return (
    <ButtonGroup>
      {opciones.map((opcion) => (        
          <Button_Outline >{opcion.title}</Button_Outline>
      ))}
    </ButtonGroup>
  )
 }
  
}
export default ContainerEcoOptions