import styled from 'styled-components'
import { Container, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Divider } from '@mui/material';
import logo_letras from '../../assets/images/logo_letras.png';
import ContainerEco from '../components/ContainerEco'

import FunctionsCommon from '../functions/FunctionsCommon';

import useMediaQuery from '@mui/material/useMediaQuery';

const Image = styled.img`
    height: 50px;
    width: 150px;
    cursor: pointer; 
    z-index: 1;
`
const DividerUI = styled(Divider)`
width: 100%;
`
export const InputUI = styled.input`
    borderRadius: 15px;
    border: 1px;
    height: 50px;
    width: 95%;
    type: search;
    arial-label: Búsqueda;
    margin: 10px;
`

export const ContainerUI = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    justify-content: space-between;
    position: relative;
`
export const Container_Inline = styled.div`
    display: inline;
`

export const H1 = styled.h1`
    width: 100%;
`
export const DivUI = styled.div`
    width: 100%;        
    max-height: 125px;
`

export const DivUIPrincipal = styled.div`
    width: 100%;
    background: transparent;
    padding-bottom: 1px;
`

const irAlInicio = () => () => {
    window.location.replace("/");
};

export function HeaderUI(props) {

    const m320 = useMediaQuery('(max-width:320px)');
    const m360 = useMediaQuery('(max-width:360px)');
    const m375 = useMediaQuery('(max-width:375px)');
    const m411 = useMediaQuery('(max-width:411px)');
    const m414 = useMediaQuery('(max-width:414px)');
    const m425 = useMediaQuery('(max-width:425px)');
    const m768 = useMediaQuery('(max-width:768px)');
    const m1024 = useMediaQuery('(max-width:1024px)');
    const m1440 = useMediaQuery('(max-width:1440px)');
    const m1600 = useMediaQuery('(max-width:1600px)');
    const m1800 = useMediaQuery('(max-width:1800px)');

    return (
        <Container>
            <ContainerUI>
                <Button
                    variant="outlined"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={props.toggleDrawer(true)}
                    sx={{
                        justifyContent: 'center',
                        marginRight: 0,
                        paddingTop: (m320 || m360 || m375 || m425) ? '0px' : '0px',
                        paddingBottom: (m320 || m360 || m375 || m425) ? '0px' : '0px',
                        paddingRight: (m320 || m360 || m375 || m425) ? '16px' : '0px',
                        paddingLeft: (m320 || m360 || m375 || m425) ? '16px' : '0px',
                        width: (m320 || m360 || m375 || m425) ? '20px' : '0px',
                        visibility: (m320 || m360 || m375 || m425) ? 'visible' : 'hidden'
                    }}
                >
                    <MenuIcon
                        sx={{
                            color: 'azure',
                            fontSize: '2.5rem',                            
                        }}
                    />
                </Button>
                <Image
                    onClick={irAlInicio()}
                    src={logo_letras}
                    style={{
                        marginLeft: (m320 || m360 || m375 || m425) ? '16px' : '-44pc',
                    }}
                />
                <Typography
                    sx={{
                        fontSize: { sm: 28, },
                        fontStyle: 'italic',
                        fontWeight: 'bold',
                        color: 'azure',
                        paddingLeft: 1
                    }}
                    variant="body1"
                    color="text.secondary"
                >
                    ...turismo a la carta
                </Typography>
            </ContainerUI>
            <DividerUI />
            <ContainerEco
                localidades={props.localidades}
                clickBuscar={props.clickBuscar}
                buscarPrestador={props.buscarPrestador}
                ecoSistemaSeleccionado={FunctionsCommon.ecoSistemaSeleccionado}
                loading={props.loading}
            />
            {/* <ContainerUI>
                <Autocomplete prestadores={props.prestadores} filter={props.filter} />
                <ButtonFind onClick={() => onClick(props.filter)} />
            </ContainerUI> */}
        </Container>
    )
}

export default ContainerUI;