import axios from "axios";

class CommentsService {

    crear = async (codigo_prestador, codigo_usuario, puntuacion, comentario, telefono, email) => {        
        
        return axios.post(`${this.obtenerURL_API()}` + "/api/prestadores_comentarios/", {
            codigo_prestador,
            codigo_usuario,
            puntuacion,
            comentario,
            telefono,
            email
        })
    }

    obtenerURL_API() { //PARA OBTENER URL EN ENTORNOS PRODUCTIVOS
        let url_principal = window.location.host;
        //console.log(url_principal);
        if (url_principal.toString().includes('localhost:3000')) {
            return process.env.REACT_APP_URL_API_DEV
        } else {
            return process.env.REACT_APP_URL_API
        }
    }

}

export default new CommentsService();