import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

import {
    Button,
    Collapse,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Card,
    ButtonBase,
    Stack,
    Rating,
    Typography,
    IconButton,
    Alert,
    AlertTitle
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import { useTheme } from '@mui/material/styles';

import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import useMediaQuery from '@mui/material/useMediaQuery';

import Skeleton from '@mui/material/Skeleton';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0.1),
    boxShadow: 'none',
    background: 'transparent',
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const Img = styled('img')({
    margin: 0,
    height: '-webkit-fill-available',
    width: '100%',
    objectFit: 'cover',
    display: 'flex',
});

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export default function CardReponsive(props) {
    const [expand, setExpand] = React.useState(false);
    const [idprestador, setIDPrestador] = React.useState(-1);

    //console.log('prueba', props.prueba);

    const [open, setOpen] = React.useState({});

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleExpandClick = (id) => {
        setOpen((prevState => ({ ...prevState, [id]: !prevState[id] })));
        //console.log(open);
        setExpand(!expand);
        setIDPrestador(id);
    }

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.prestadores.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const useStyles = makeStyles(() => ({
        actionArea: {
            borderRadius: 10,
            boxShadow: `none`,
            transition: '0.1s',
            background: 'transparent',
            '&.css-bhp9pd-MuiPaper-root-MuiCard-root': {
                borderRadius: 10,
                overflow: 'unset',
            },
            '&.css-s18byi': {
                overflow: 'unset',
            }
        },
        alertInfo: {
            backgroundColor: '#EBEFFF',
            height: '100%',
            '&:hover': {
                transform: 'scale(1.0001)',
                boxShadow: `0 1px 3px 0`,
                backgroundColor: '#D1E9F6',
                cursor: 'pointer',
                '& .css-132jxnu-MuiPaper-root': {
                    backgroundColor: '#D1E9F6'
                }
            },

        },
        alertSuccess: {
            backgroundColor: '#EFFCF4',
        },
        paperStyle: {
            '&.MuiPaper-root': {
                boxShadow: 'none',
                backgroundColor: '#EBEFFF',
            },
        },
        stackDetails: {
            '&.css-29nqov-MuiStack-root>:not(style)+:not(style)': {

            }
        },
        selectDropdown: { color: "#000", backgroundColor: "#fff" },
        menuItem: {
            "&:hover": {
                backgroundColor: "#3b3f58",
                color: '#fff'
            }
        }
    }));

    const classes = useStyles();

    const matches = useMediaQuery('(max-width:600px)');

    return (
        <Container component="section" sx={{ mt: 8, mb: 4, background: 'transparent' }}>
            <Grid container spacing={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '-20px' }}>
                <Card className={classes.actionArea}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={0.1}>
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                            >
                                <Grid item sx={{
                                    paddingTop: 0.7,
                                    paddingLeft: 0.5,
                                    marginRight: { xs: 0, sm: 0, md: 0, xl: 0, lg: 0 },
                                    marginLeft: { xs: 0, sm: 0, md: 0, xl: 0, lg: 0 },
                                }}>
                                    <Skeleton
                                        sx={{
                                            width: { xs: 300, md: 300, sm: 200, xl: 200, lg: 200 },
                                            height: 200,
                                            margin: 0,
                                            display: 'flex',
                                            objectFit: { xs: 'cover' },
                                            marginLeft: { xs: '20px', sm: '20px', md: '20px', xl: '20px', lg: '0px' },
                                            maxHeight: { xs: 100, md: 200, sm: 200 },
                                            marginRight: { xs: '0px' },
                                            maxWidth: { xs: 300, md: 200, sm: 200 },
                                            borderRadius: '10px 0px 0px 0px',
                                        }}
                                        variant="rectangular"
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid item
                                        sx={{
                                            height: 70,
                                            marginTop: 0,
                                            paddingTop: 0,
                                            marginRight: { xs: 0, sm: 0, md: 0, xl: 0, lg: 0 },
                                            marginLeft: { xs: 0, sm: 0, md: 0, xl: 0, lg: 0 },
                                        }}
                                    >
                                        <Item sx={{
                                            height: 70,
                                            paddingLeft: 0.5,
                                            paddingRight: 0.5,
                                            marginTop: 0,
                                            paddingTop: 0,                                                 
                                            marginRight: { xs : 0, sm: 0, md: '0px', xl: 0, lg: 0},
                                            marginLeft: { xs : '20px', sm: '0px', md: '0px', xl: '0px', lg: '0px'},
                                        }}
                                        >
                                            <Skeleton
                                                sx={{
                                                    height: '130px',
                                                    position: 'relative',
                                                    top: '-22px',                                                                                                      
                                                    maxWidth: { xs: 300, sm: 300, md: 500, xl: 600, lg: 600 },
                                                    marginLeft: { sm: '0px', md: 0, xs: 0, lg: 0 },
                                                    marginRight: { sm: 0, md: 0, xs: 0, lg: '0px' },
                                                }}
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item
                                        sx={{
                                            width: {
                                                xs: 0,
                                                sm: 190,
                                                md: 400,
                                                xl: 550,
                                                lg: 600
                                            },
                                            height: {
                                                xs: 15,
                                                sm: 100,
                                                md: 100,
                                                xl: 100,
                                                lg: 100
                                            },
                                        }}
                                    >
                                        <Item sx={{ paddingLeft: 0.5, paddingRight: 0.5, }}>
                                            <Skeleton sx={{
                                                minHeight: { sm: 195, md: 190, xl: 195, lg: 190 },
                                                position: 'relative',
                                                top: { lg: '-23px', md: '-20px', sm: '-25px', xl: '-25px' },
                                                paddingBottom: { sm: '0px' },
                                            }} />
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{
                                    width: {
                                        xs: 330,
                                        sm: 330,
                                        md: 330,
                                        xl: 330,
                                        lg: 330
                                    },
                                    paddingTop: 0.7,
                                }}>
                                    <Skeleton
                                        sx={{
                                            width: {
                                                xs: 300,
                                                sm: 300,
                                                md: 330,
                                                xl: 330,
                                                lg: 330
                                            },
                                            height: 200,
                                            borderRadius: '0px 10px 0px 0px',
                                            marginLeft: { xs: '25px' },
                                            marginRight: { xs: 0, sm: 0, md: 0, xl: 0, lg: 0 }
                                        }}
                                        variant="rectangular"
                                    />
                                </Grid>
                            </Stack>
                        </Grid>
                    </Box>
                    <CardActions disableSpacing>
                        <Skeleton variant="circular" sx={{ marginLeft: { xs: '15px', sm: '15px', md: '15px', xl: '15px', lg: '0px', } }}>
                            <Avatar />
                        </Skeleton>
                    </CardActions>
                </Card>
            </Grid>
        </Container>
    );
}
