import * as React from 'react';

import {
    Marker,
    Popup,
    Tooltip,
} from 'react-leaflet'

import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';
import marker from '../../assets/images/marker.png'
import iconShadow from '../../assets/images/marker-shadow.png'

import LocationOnIcon from '@mui/icons-material/LocationOn';

import { Alert, Box} from '@mui/material';
import { alpha } from '@mui/material/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import L from 'leaflet'

import "../../assets/css/leaflet.awesome-markers.css"
import "../../assets/js/leaflet.awesome-markers.js"

const IconLocation = L.icon({
    iconUrl: marker,
    iconRetinaUrl: marker,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 35],
    className: 'leaflet-marker-icon',
});

export default function MarkersLocation(props) {

    const redirigir = () => {
        window.open(`https://www.google.com/maps/search/?api=1&query=${props.lat},${props.lon}&zoom=20`);
    }

    return (
        <Marker
            position={{ lat: props.lat, lng: props.lon }}
            icon={IconLocation}
        >
            <Popup>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        bgcolor: 'background.paper',
                        overflow: 'hidden',
                        borderRadius: '12px',
                        boxShadow: 1,
                        fontWeight: 'bold',
                        height: 100,
                        paddingLeft: 0,
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            height: 100,
                            width: 100,
                        }}
                        alt="The house from the offer."
                        src={props.imagen_mapa}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: { xs: 'center', md: 'flex-start' },
                            m: 0,
                            minWidth: { md: 150 },
                        }}
                    >
                        <Box component="span" sx={{ fontSize: 16, mt: 0, width: 160 }}>
                            {props.nombre}
                        </Box>
                        <Alert
                            severity="success"
                            variant="filled"
                            onClick={redirigir}
                            sx={{
                                mt: 1.5,
                                p: 0.5,
                                width: 150,
                                height: 40,                                
                                fontWeight: 'bold',
                                display: 'flex',
                                fontSize: 11,
                                alignItems: 'center',
                                '& svg': {
                                    fontSize: 21,
                                    mr: 0.5,
                                },
                                cursor:'pointer'
                            }}
                            iconMapping={{
                                success: <MapTwoToneIcon fontSize="inherit" />,
                            }}
                        >
                            IR A GOOGLE MAPS
                        </Alert>

                    </Box>
                </Box>
            </Popup>
        </Marker>
    );
}
